:root {
  --color-navy-100: #001f4e;
  --color-sapphire-130: #2e408a;
  --color-sapphire-100: #425cc7;
  --color-sapphire-90: #546ccc;
  --color-sapphire-30: #c6ceee;
}

* {
  font-family: Lato, sans-serif;
}

body, html {
  background-color: #e5e5e5;
  margin: 0;
  padding: 0;
}

pre {
  white-space: pre;
  font-family: monospace;
}

.header {
  background-color: var(--color-navy-100);
  z-index: 1;
  color: #fff;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
}

.header > h2 {
  text-align: center;
  margin: 0;
  padding-top: 32px;
}

.app-introduction-box {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  max-width: 48rem;
  margin-top: -3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.app-introduction-title {
  text-align: left;
  align-items: normal;
  width: calc(100% - 6rem);
  max-width: 38rem;
  margin-top: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 1rem 0;
  font-family: Lato-Bold, sans-serif;
  font-style: normal;
  font-weight: bold;
  display: flex;
}

.app-paragraph {
  text-align: left;
  align-items: normal;
  width: calc(100% - 6rem);
  max-width: 38rem;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 1rem 0;
  font-family: Lato-Regular, sans-serif;
  line-height: 32px;
  display: flex;
}

.app-no-research-paragraph {
  text-align: left;
  align-items: normal;
  width: calc(100% - 6rem);
  max-width: 38rem;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 1rem 0 6rem;
  font-family: Lato-Regular, sans-serif;
  line-height: 32px;
  display: flex;
}

.button {
  letter-spacing: 1.5%;
  background-color: var(--color-sapphire-100);
  color: #fff;
  box-shadow: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 304px;
  height: 40px;
  margin-top: 6rem;
  margin-bottom: 3rem;
  padding: 8px 16px 10px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
}

.button-text {
  width: 70px;
}

.button:disabled {
  cursor: not-allowed;
  opacity: .4;
}

.content {
  text-align: center;
  margin-top: 24px;
}

.button-primary:enabled:hover {
  background-color: var(--color-sapphire-90);
}

.button-primary:enabled:focus {
  background-color: var(--color-sapphire-130);
}

.button-primary:enabled:active {
  background-color: var(--color-sapphire-130);
  box-shadow: none;
}

.error-message {
  color: red;
  margin-top: 8px;
  font-size: 24px;
}

.assessment {
  z-index: 2;
  background-color: #efefef;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-image {
  padding-top: 3rem;
}
/*# sourceMappingURL=index.0c79f936.css.map */
